<template>
  <div class="row">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <th>
                        رقم الطلب
                    </th>
                    <th>
                        العميل
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        المبلغ
                    </th>
                    <th>
                        الحالة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="u in orders" :key="u._id">
                        <td>
                            {{ u.order_id }}
                        </td>
                        <td>
                            {{ u.user_name }}
                        </td>
                        <td>
                            {{ u.date.replace("T", ' ').split(".")[0] }}
                        </td>
                        <td>
                            {{ u.amount }}
                        </td>
                        <td>
                            <select v-model="u.status" @change="changeExdate(u._id, u.status)">
                                <option :value="0">لم تتم</option>
                                <option :value="1">ناجحة</option>
                                <option :value="2">فاشلة</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            orders: []
        }
    },
    created(){
        var g = this;
      $.post(api + "/admin/orders", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          r = r;
          g.orders = r.response;
          g.loading = false;
        })
        .catch(function (e) {
          g.allowed = false;
          alert("تعذر الإتصال بالخادم..");
        });
    },
    methods: {
        changeExdate(id, status){
            if(confirm("متاكد من تغيير حالة الطلب")){
                var g = this;
                $.post(api + "/admin/order-status", {
                    jwt: this.user.jwt,
                    status: status,
                    id: id
                })
                    .then(function (r) {
                   alert("تم التغيير بنجاح!")
                    })
                    .catch(function (e) {
                    g.allowed = false;
                    alert("تعذر الإتصال بالخادم..");
                    });
            }
        }
    }
}
</script>

<style>

</style>